import React, { useContext } from "react";
import CeWrapper from "../../../global/CeWrapper";
import JobsWidget from "../../../../../jobs/components/widgets/JobsWidget/index";
import CeDataGetter from "../../../../libs/CeDataGetter";
import { PageContext } from "../../../../context/PageContext";

const JobsWidgetCe = ({ ceData }) => {
  const { analyticsEvents } = useContext(PageContext);

  return (
    <CeWrapper ceData={ceData} renderHeader={true}>
      <JobsWidget
        ceId={CeDataGetter.getUid(ceData)}
        employer={ceData.contentParts.employer}
        itemCount={ceData.contentParts.itemCount}
        disableBoosting={ceData.contentParts.disableBoosting}
        showNewJobsCount={ceData.contentParts.showNewJobsCount}
        layout={ceData.properties.layout}
        filterPositions={ceData.contentParts.filterPositions}
        filterActivities={ceData.contentParts.filterActivities}
        filterCareerlevels={ceData.contentParts.filterCareerlevels}
        filterCities={ceData.contentParts.filterCities}
        filterFederalStates={ceData.contentParts.filterFederalStates}
        professionalGroup={ceData.contentParts.professionalGroup}
        filterTopJobs={ceData.contentParts.filterTopJobs}
        filterStartpageTopJobs={ceData.contentParts.filterStartpageTopJobs}
        moreJobsButtonText={ceData.contentParts.moreJobsButtonText}
        moreJobsOnClick={() =>
          analyticsEvents?.clickEmployerJobsWidget &&
          analyticsEvents.clickEmployerJobsWidget(
            ceData.id,
            CeDataGetter.getCeMainHeadlineTitle(ceData)
          )
        }
        hideParentContainerWhenNoJobsFound={
          ceData.contentParts.hideParentContainerWhenNoJobsFound
        }
        showInOnPageNavigation={CeDataGetter.getShowInOnPageNavigation(ceData)}
      />
    </CeWrapper>
  );
};

export default JobsWidgetCe;
